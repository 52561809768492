<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'ProfileOverviewHistory' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>
    </div>

    <!-- If there's some data -->
    <div
      v-if="response"
      class="mt-6"
    >
      <v-card
        flat
        class="py-6 px-4"
      >
        <!-- Show the title and menu -->
        <div class="d-flex justify-space-between">
          <div>
            <profile-chip
              :platform="response.platform"
              :data="response.influencer_preview"
            />

            <div class="mt-4">
              <v-tooltip v-if="response.influencer_preview.followers" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>people</v-icon>
                    </v-avatar>

                    {{ nFormatter(response.influencer_preview.followers, 0) }}
                  </v-chip>
                </template>

                <span>Followers</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.likes" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>thumb_up</v-icon>
                    </v-avatar>

                    {{ nFormatter(computedAverageValues.likes, 0) }}
                  </v-chip>
                </template>

                <span>Average Likes</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.views" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>play_circle</v-icon>
                    </v-avatar>

                    {{ nFormatter(computedAverageValues.views, 0) }}
                  </v-chip>
                </template>

                <span>Average Views</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.comments" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>chat_bubble</v-icon>
                    </v-avatar>

                    {{ nFormatter(computedAverageValues.comments, 0) }}
                  </v-chip>
                </template>

                <span>Average Comments</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.shares" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>share</v-icon>
                    </v-avatar>

                    {{ nFormatter(computedAverageValues.shares, 0) }}
                  </v-chip>
                </template>

                <span>Average Shares</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.saves" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>bookmark</v-icon>
                    </v-avatar>

                    {{ nFormatter(computedAverageValues.saves, 0) }}
                  </v-chip>
                </template>

                <span>Average Saves</span>
              </v-tooltip>

              <v-tooltip v-if="computedAverageValues.engagement_rate" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="secondary" class="ml-2" text-color="white" v-bind="attrs" v-on="on">
                    <v-avatar left>
                      <v-icon small>bar_chart</v-icon>
                    </v-avatar>

                    {{ computedAverageValues.engagement_rate }}%
                  </v-chip>
                </template>

                <span>Average Engagement Rate</span>
              </v-tooltip>
            </div>
          </div>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isMakingRequest"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="shouldShowEditDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                @click="shouldShowDeleteDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the copy URL button -->
              <v-list-item
                @click="copyReportLink"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the option to trigger export as XLSX -->
              <v-list-item
                @click="triggerDownload('xlsx')"
                :disabled="isMakingDownloadRequest.xlsx"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download XLSX
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex justify-space-between align-end flex-wrap mt-6 mb-4 text-subtitle-2">
          <!-- Show the date -->
          <div>
            {{ formatDate(response.created_at) }}
          </div>

          <div class="d-flex align-center">
            <div
              v-if="response.averageSponsoredValues.count"
              class="d-flex align-center mr-3"
            >
              <span class="mr-4 text-subtitle-2">
                Only Sponsored Posts?
              </span>

              <v-switch
                v-model="onlySponsoredPosts"
                @change="handleSponsoredPostsChange"
                class="ma-0"
                color="primary"
                hide-details
                inset
              ></v-switch>
            </div>

            <!-- Show the number of posts -->
            <v-chip
              color="primary"
              small
            >
              {{ postsCount }} {{ postsCount > 1 ? "Posts" : "Post" }} Found
            </v-chip>
          </div>
        </div>

        <v-divider class="my-4" />

        <!-- Show the report brief -->
        <div class="my-4 d-flex flex-wrap align-center">
          <span class="text-subtitle-2">
            Date Range:
          </span>
          &nbsp;
          <v-chip
            small
          >
            {{ dayjs(response.start_date).format("ll") }}
          </v-chip>
          &nbsp;-&nbsp;
          <v-chip
            small
          >
            {{ dayjs(response.end_date).format("ll") }}
          </v-chip>
        </div>

        <!-- Show the report filters -->
        <v-row>
          <v-col
            v-for="post in posts"
            :key="post.id"
            cols="12"
            lg="6"
          >
            <post-item
              :post="post"
            />
          </v-col>
        </v-row>

        <!-- DOM element to be used to track scroll -->
        <div id="intersect-detector" ref="intersectDetector" v-intersect="handleScrollIntersect"></div>
      </v-card>
    </div>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete this report. This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="response">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const PostItem = () => import(/* webpackChunkName: "post-item" */ "@/blocks/common/PostItem")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

// Subscription ID for messageEvents
const subscriptionId = Symbol("ProfileOverview/Report")

// Export the SFC
export default {
  // Name of the component
  name: "ProfileOverviewReport",

  // Register the components
  components: {
    PostItem,
    ProfileChip
  },

  // Define local data variables
  data: () => ({
    // The loading state
    isLoading: false,
    // The report data
    response: null,
    // The posts data
    posts: [],
    postsPagination: 0,
    isFetchingPosts: false,
    onlySponsoredPosts: false,

    // Whether or not we're loading
    isMakingRequest: false,
    shouldShowDeleteDialog: false,
    shouldShowEditDialog: false,

    // The form data
    form: {
      sharingAccess: "self"
    },

    // Whether or not is making a download
    isMakingDownloadRequest: {
      pdf: false,
      xlsx: false
    },
    triggerId: {
      pdf: null,
      xlsx: null
    },
    downloadLoaders: {
      pdf: null,
      xlsx: null,
    },
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the sharing access options
     *
     * @returns {Array}
     */
    postsCount() {
      return this.onlySponsoredPosts ? this.response.averageSponsoredValues.count : this.response.posts_count
    },

    /**
     * Compute the maximum number of pages possible
     *
     * @returns {number}
     */
    maxPagesPossible() {
      return Math.ceil(this.postsCount / 4)
    },

    /**
     * Compute the sharing access options
     *
     * @returns {Array}
     */
    computedAverageValues() {
      return this.onlySponsoredPosts ? this.response.averageSponsoredValues : this.response.averageValues
    }
  },

  // Define vuelidate validation rules
  validations: {
    form: {}
  },

  // Define local method functions
  methods: {
    /**
     * Handle the scroll event for the table rows
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleScrollIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If there are no posts, return
      if (!this.posts.length) return

      // Otherwise
      this.fetchPosts()
    },

    /**
     * Fetch the posts for the report
     *
     * @returns {void}
     */
    async fetchPosts() {
      // Check if we're already fetching
      if (this.isFetchingPosts) return

      // If we've reached the maximum number of pages
      if (this.postsPagination >= this.maxPagesPossible) return

      // Mark as fetching and show a loader
      const loaderId = Symbol()
      this.isFetchingPosts = true
      this.$store.dispatch("loaders/add", loaderId)

      // Make the network request
      try {
        // Increment the page
        this.postsPagination++

        // Use the axios helper function
        const response = await axios({
          url: `/api/profile-overviews/${this.response.id}/posts?onlySponsored=${this.onlySponsoredPosts}`,
          params: {
            page: this.postsPagination,
          }
        })

        // Set the posts data
        this.posts = [
          ...this.posts,
          ...response.data.data
        ]
      }
      // Catch the error
      catch (error) {
        logger({ error, type: "ProfileOverview/Report/fetchPosts" })
      }
      // Nonetheless
      finally {
        // Mark as not fetching and hide the loader
        this.isFetchingPosts = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Handle the change in the sponsored posts switch
     *
     * @returns {void}
     */
    handleSponsoredPostsChange() {
      // Reset the pagination
      this.postsPagination = 0

      // Reset the posts
      this.posts = []

      // Fetch the posts
      this.fetchPosts()
    },

    /**
     * Delete the report
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/profile-overviews/${this.response.id}`,
          method: "DELETE"
        })

        // If successful, redirect to the index
        this.$router.replace({ name: "ProfileOverviewHistory" })
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "ProfileOverview/Delete Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Hide the dialog
      this.shouldShowDeleteDialog = false
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("profileOverview/updateItem", { ...this.response, ...this.form })

      // Reset the request state
      this.isMakingRequest = false
    },

    /**
     * Handle the request to export this report as PDF
     *
     * @param {String} type
     * @returns {void}
     */
     async triggerDownload(type) {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDownloadRequest[type] = true

      // Make a network request
      try {
        // Set a triggerId
        this.triggerId[type] = String(Date.now())

        // Use helper function
        await axios({
          url: `/api/profile-overviews/${this.response.id}/download/${type}`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId[type]
          }
        })
      }
      // Catch the error
      catch (error) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try later." })

        // Hide the loader
        this.isMakingDownloadRequest[type] = false
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please wait while we generate your report..." })
      }
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-profile-overview-xlsx-started":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.xlsx = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.xlsx)

          break

        case "generate-profile-overview-xlsx-completed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break

        case "generate-profile-overview-xlsx-failed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink() {
      // Copy the link value
      const route = this.$router.resolve({ name: "ProfileOverviewReport", params: { uuid: this.response.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },
  },

  /**
   * As soon as the data is ready
   *
   * @returns {void}
   */
  async created() {
    // Show the loaders
    const loaderId = Symbol("ProfileOverviewReport")
    this.$store.dispatch("loaders/add", loaderId)
    this.isLoading = true

    // Fetch the report data
    try {
      // Try making the request
      const response = await axios(`/api/profile-overviews/${this.$route.params.uuid}`)

      // Set the report data
      this.response = response.data

      // Set the form values
      this.form.sharingAccess = this.response.sharing_access

      // Register a subscriber for messageEvents
      messageEvents.register({
        id: subscriptionId,
        module: "profile-overview",
        type: "all",
        key: "all",
        validator: (event) => event.module === "profile-overview" && event.localData.modelId === this.response.id,
        callback: this.handleMessageEvent
      })

      // Fetch the posts
      this.fetchPosts()
    } catch (error) {
      // log using the helper function
      logger({ type: "ProfileOverview/created Fetch Report", error })

      this.$store.dispatch("toasts/add", { text: "An error occurred!" })
    } finally {
      // Hide the loaders
      this.isLoading = false
      this.$store.dispatch("loaders/remove", loaderId)
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
   beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>
